/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    h2: "h2",
    strong: "strong",
    br: "br",
    h3: "h3",
    ol: "ol",
    a: "a",
    h4: "h4",
    pre: "pre",
    code: "code"
  }, _provideComponents(), props.components), {ContentFilters, Admonition, FilteredContent, RequestParameters, RequestSample, ResponseBody, ResponseSample} = _components;
  if (!Admonition) _missingMdxReference("Admonition", true);
  if (!ContentFilters) _missingMdxReference("ContentFilters", true);
  if (!FilteredContent) _missingMdxReference("FilteredContent", true);
  if (!RequestParameters) _missingMdxReference("RequestParameters", true);
  if (!RequestSample) _missingMdxReference("RequestSample", true);
  if (!ResponseBody) _missingMdxReference("ResponseBody", true);
  if (!ResponseSample) _missingMdxReference("ResponseSample", true);
  return React.createElement(React.Fragment, null, React.createElement(ContentFilters, {
    filters: ["Request parameters", "Request samples", "Response body", "Response sample"]
  }), "\n", React.createElement(_components.p, null, "A merchant can use the payment details of a card payment to run a referenced refund. To run a referenced refund, the merchant should first retrieve the payment information in one of the following ways:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Use the paymentId."), "\n", React.createElement(_components.li, null, "Search for the payment using payment information such as the bank account number."), "\n"), "\n", React.createElement(_components.h2, null, "Integration steps"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Step 1."), " Retrieve information about the original payment.", React.createElement(_components.br), "\n", React.createElement(_components.strong, null, "(Optional) Step 1a."), " Retrieve information about the payment using the paymentId.", React.createElement(_components.br), "\n", React.createElement(_components.strong, null, "(Optional) Step 1b."), " Retrieve information about the payment without the paymentId.", React.createElement(_components.br), "\n", React.createElement(_components.strong, null, "Step 2."), " Refund the payment."), "\n", React.createElement(_components.h2, null, "Before you begin"), "\n", React.createElement(_components.h3, null, "Bearer tokens"), "\n", React.createElement(_components.p, null, "Use our Identity Service to generate a Bearer token to include in the header of your requests. To generate your Bearer token, complete the following steps:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Include your API key in the x-api-key parameter in the header of a POST request."), "\n", React.createElement(_components.li, null, "Send your request to ", React.createElement(_components.a, {
    href: "https://identity.payroc.com/authorize"
  }, "https://identity.payroc.com/authorize"), "."), "\n"), "\n", React.createElement(Admonition, null, React.createElement(_components.p, null, "You need to generate a new Bearer token before the previous Bearer token expires.")), "\n", React.createElement(FilteredContent, {
    filter: "Request samples"
  }, React.createElement(_components.h4, null, "Example request"), React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "curl --location --request POST  'https://identity.payroc.com/authorize' --header 'x-api-key: <api key>'\n"))), "\n", React.createElement(FilteredContent, {
    filter: "Response sample"
  }, React.createElement(_components.h4, null, "Example response"), React.createElement(_components.p, null, "If your request is successful, we return a response that contains your Bearer token, information about its scope, and when it expires."), React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\n\"access_token\": \"eyJhbGc....adQssw5c\",\n\"expires_in\": 3600,\n\"scope\": \"service_a service_b\",\n\"token_type\": \"Bearer\"\n}\n"))), "\n", React.createElement(_components.h3, null, "Headers"), "\n", React.createElement(_components.p, null, "To create the header of each GET request, you must include the following parameters:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Content-Type:"), " Include application/json as the value for this parameter."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Authorization:"), " Include your Bearer token in this parameter."), "\n"), "\n", React.createElement(FilteredContent, {
    filter: "Request samples"
  }, React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "curl\n-H \"Content-Type: application/json\"\n-H \"Authorization: <Bearer token>\"\n"))), "\n", React.createElement(_components.p, null, "To create the header of each POST request, you must include the following parameters:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Content-Type:"), " Include application/json as the value for this parameter."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Authorization:"), " Include your Bearer token in this parameter."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Idempotency-Key:"), " Include a UUID v4 to make the request idempotent."), "\n"), "\n", React.createElement(FilteredContent, {
    filter: "Request samples"
  }, React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "curl\n-H \"Content-Type: application/json\"\n-H \"Authorization: <Bearer token>\"\n-H \"Idempotency-Key: <UUID v4>\"\n"))), "\n", React.createElement(_components.h3, null, "Errors"), "\n", React.createElement(_components.p, null, "If your request is unsuccessful, we return an error. For more information about errors, see ", React.createElement(_components.a, {
    href: "https://docs.payroc.com/api/errors"
  }, "Errors"), "."), "\n", React.createElement(_components.h2, null, "Step 1 - Retrieve the details of the payment"), "\n", React.createElement(_components.h3, null, "1a (Optional) – Retrieve the details of the payment with a paymentId"), "\n", React.createElement(_components.p, null, "Send a GET request with the paymentID to our Bank Transfer Payments endpoint."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Testing endpoint:"), " ", React.createElement(_components.a, {
    href: "https://api.uat.payroc.com/v1/bank-transfer-payments/%7Bpaymentid%7D"
  }, "https://api.uat.payroc.com/v1/bank-transfer-payments/{paymentid}"), React.createElement(_components.br), "\n", React.createElement(_components.strong, null, "Production endpoint:"), " ", React.createElement(_components.a, {
    href: "https://api.payroc.com/v1/bank-transfer-payments/%7Bpaymentid%7D"
  }, "https://api.payroc.com/v1/bank-transfer-payments/{paymentid}")), "\n", React.createElement(FilteredContent, {
    filter: "Request parameters"
  }, React.createElement(_components.h3, null, "Request parameters"), React.createElement(_components.p, null, "To create the body of your request, use the following parameters:"), React.createElement(RequestParameters, {
    operationId: "getBankTransferPayment"
  })), "\n", React.createElement(FilteredContent, {
    filter: "Request samples"
  }, React.createElement(_components.h3, null, "Example request"), React.createElement(RequestSample, {
    operationId: "getBankTransferPayment"
  })), "\n", React.createElement(FilteredContent, {
    filter: "Response body"
  }, React.createElement(_components.h3, null, "Response fields"), React.createElement(_components.p, null, "If your request is successful, our gateway returns the details of the payment. The response contains the following fields:"), React.createElement(ResponseBody, {
    operationId: "getBankTransferPayment"
  })), "\n", React.createElement(FilteredContent, {
    filter: "Response sample"
  }, React.createElement(_components.h3, null, "Example response"), React.createElement(ResponseSample, {
    operationId: "getBankTransferPayment"
  })), "\n", React.createElement(_components.h3, null, "1b (Optional) – Retrieve the details of the payment without a paymentId"), "\n", React.createElement(_components.p, null, "Send a GET request to our Bank Transfer Payments endpoint. To filter your results, use query parameters."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Test endpoint:"), " ", React.createElement(_components.a, {
    href: "https://api.uat.payroc.com/v1/bank-transfer-payments"
  }, "https://api.uat.payroc.com/v1/bank-transfer-payments"), React.createElement(_components.br), "\n", React.createElement(_components.strong, null, "Production endpoint:"), " ", React.createElement(_components.a, {
    href: "https://api.payroc.com/v1/bank-transfer-payments"
  }, "https://api.payroc.com/v1/bank-transfer-payments")), "\n", React.createElement(FilteredContent, {
    filter: "Request parameters"
  }, React.createElement(_components.h3, null, "Request parameters"), React.createElement(_components.p, null, "To create the body of your request, use the following parameters:"), React.createElement(RequestParameters, {
    operationId: "listBankTransferPayments"
  })), "\n", React.createElement(FilteredContent, {
    filter: "Request samples"
  }, React.createElement(_components.h3, null, "Example request"), React.createElement(RequestSample, {
    operationId: "listBankTransferPayments"
  })), "\n", React.createElement(FilteredContent, {
    filter: "Response body"
  }, React.createElement(_components.h3, null, "Response fields"), React.createElement(_components.p, null, "If your request is successful, our gateway returns a list of payments that match the query parameters. The response contains the following fields:"), React.createElement(ResponseBody, {
    operationId: "listBankTransferPayments"
  })), "\n", React.createElement(FilteredContent, {
    filter: "Response sample"
  }, React.createElement(_components.h3, null, "Example response"), React.createElement(ResponseSample, {
    operationId: "listBankTransferPayments"
  })), "\n", React.createElement(_components.h2, null, "Step 2 - Refund the payment"), "\n", React.createElement(_components.p, null, "To refund the payment, send a POST request to our Bank Transfer Payments Refund endpoint."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Test endpoint:"), " ", React.createElement(_components.a, {
    href: "https://api.uat.payroc.com/v1/bank-transfer-payments%7Bpaymentid%7D/refund"
  }, "https://api.uat.payroc.com/v1/bank-transfer-payments{paymentid}/refund"), React.createElement(_components.br), "\n", React.createElement(_components.strong, null, "Production endpoint:"), " ", React.createElement(_components.a, {
    href: "https://api.payroc.com/v1/bank-transfer-payments%7Bpaymentid%7D/refund"
  }, "https://api.payroc.com/v1/bank-transfer-payments{paymentid}/refund")), "\n", React.createElement(FilteredContent, {
    filter: "Request parameters"
  }, React.createElement(_components.h3, null, "Request parameters"), React.createElement(_components.p, null, "To create the body of your request, use the following parameters:"), React.createElement(RequestParameters, {
    operationId: "refundBankTransferPayment"
  })), "\n", React.createElement(FilteredContent, {
    filter: "Request samples"
  }, React.createElement(_components.h3, null, "Example request"), React.createElement(RequestSample, {
    operationId: "refundBankTransferPayment"
  })), "\n", React.createElement(FilteredContent, {
    filter: "Response body"
  }, React.createElement(_components.h3, null, "Response fields"), React.createElement(_components.p, null, "If your request is successful, our gateway refunds the payment. The response contains the following fields:"), React.createElement(ResponseBody, {
    operationId: "refundBankTransferPayment"
  })), "\n", React.createElement(FilteredContent, {
    filter: "Response sample"
  }, React.createElement(_components.h3, null, "Example response"), React.createElement(ResponseSample, {
    operationId: "refundBankTransferPayment"
  })));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
